import React, { useState } from "react";
import HomeBG from "../assets/HomeBG.jpeg";
import "./Home.css";
import About1 from "../assets/About1.jpeg";
import Logo3 from "../assets/Logo3.svg";
import Shape1 from "../assets/Digital Red Panther - Shape Dividers/modified.svg";
import Shape2 from "../assets/Digital Red Panther - Shape Dividers/tri-triangle-bottom-modified.svg";
import Shape5 from "../assets/Recurso 9.svg";
import Course1 from "../assets/HomeCourse1.jpeg";
import Course2 from "../assets/HomeCourse2.jpg";
import { Link } from "react-router-dom";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import UndrawImg from "../assets/Undraw1.svg";
import Video from "../components/Video";

function Home() {
  const [slide1, setSlide1] = useState(true);
  const [slide2, setSlide2] = useState(false);

  function handleSlide1Press() {
    let slidesEl = document.querySelectorAll(".slide__title");
    if (slide1) {
      return;
    } else {
      slidesEl[0].classList.remove("slide__title--inactive");
      slidesEl[1].classList += " slide__title--inactive";
      setSlide1(true);
      setSlide2(false);
    }
  }

  function handleSlide2Press() {
    let slidesEl = document.querySelectorAll(".slide__title");
    if (slide2) {
      return;
    } else {
      slidesEl[1].classList.remove("slide__title--inactive");
      slidesEl[0].classList += " slide__title--inactive";
      setSlide1(false);
      setSlide2(true);
    }
  }

  return (
    <>
      <section id="header">
        <div className="row">
          <div className="header__container">
            <figure className="header__bg--wrapper">
              <img src={HomeBG} alt="" className="header__bg" />
            </figure>
            <div className="header__description">
              <div className="header__left">
                <h1 className="header__title">
                  {" "}
                  APRENDER ES CRECER: <br />
                  <span className="header__span">GEOMETRÍA DESCRIPTIVA</span>
                  <p className="header__span--2">GEOMETRÍA DESCRIPTIVA</p>
                </h1>
                <h3 className="header__sub-title">
                  ¿Tienes dificultades para aprender este curso o resolver
                  problemas? <br />
                  ¡No te preocupes!, aquí te ayudamos a aprender desde{" "}
                  <span style={{ color: "#192B90", fontWeight: "700" }}>
                    CERO
                  </span>
                </h3>
              </div>
              <div className="header__right">
                <img src={Logo3} alt="" className="header__logo" />
              </div>
            </div>
          </div>
        </div>
        <figure className="header__bottom-shape--wrapper">
          <img src={Shape1} alt="" className="header__shape" />
        </figure>
      </section>
      <section id="aboutme">
        <div className="row">
          <div className="aboutme__container">
            <h2 className="aboutme__title">CONÓCEME</h2>
            <div className="aboutme__container--text">
              <div className="aboutme__left">
                <figure className="aboutme__img--wrapper">
                  <img src={About1} alt="" className="aboutme__img" />
                </figure>
              </div>
              <div className="aboutme__right">
                <p>
                  Mi negocio es{" "}
                  <span style={{ color: "#192b90", fontWeight: "700" }}>
                    {" "}
                    más que solo un trabajo. <br />
                  </span>{" "}
                  Cada vez que me embarco en un nuevo proyecto, procuro que todo
                  vaya acorde con{" "}
                  <span style={{ color: "#192b90", fontWeight: "700" }}>
                    mis valores.
                  </span>{" "}
                  Así sé que los buenos resultados están garantizados.
                </p>
                <figure className="aboutme__img--wrapper-2">
                  <img src={UndrawImg} alt="" className="aboutme__img--2" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="aboutme__2">
        <div className="row">
          <div className="aboutme__slides">
            <div className="slides__title">
              <h3 className="slide__title " onClick={handleSlide1Press}>
                SOBRE MI
              </h3>
              <h3
                className="slide__title slide__title--inactive"
                onClick={handleSlide2Press}
              >
                MI PROYECTO
              </h3>
            </div>
            {slide1 ? (
              <div className="aboutme__slide aboutme__slide--1 slide__active">
                <p className="aboutme__slide--text">
                  Soy un{" "}
                  <span style={{ color: "#192b90", fontWeight: "700" }}>
                    experto
                  </span>{" "}
                  en la materia de{" "}
                  <span style={{ color: "#192b90", fontWeight: "700" }}>
                    Geometría Descriptiva{" "}
                  </span>
                  y{" "}
                  <span style={{ color: "#192b90", fontWeight: "700" }}>
                    Dibujo en Ingeniería
                  </span>{" "}
                  con más de{" "}
                  <span style={{ color: "#192b90", fontWeight: "700" }}>
                    6 años de experiencia
                  </span>{" "}
                  enseñando a jóvenes estudiantes de ingeniería y arquitectura.
                </p>
                <Video embedUrl={"https://www.youtube.com/embed/Vl0UVCGSiK4"} />
              </div>
            ) : (
              <div className="aboutme__slide aboutme__slide--2 ">
                <p className="aboutme__slide--text">
                  Siempre estoy trabajando en brindar{" "}
                  <span style={{ color: "#192b90", fontWeight: "700" }}>
                    clases didácticas
                  </span>{" "}
                  a mis alumnos, con el fin de ellos puedan{" "}
                  <span style={{ color: "#192b90", fontWeight: "700" }}>
                    aprender todo lo que necesitan
                  </span>{" "}
                  para aprobar este curso que tanto me apasiona.
                </p>
                <Video embedUrl={"https://www.youtube.com/embed/pcl_8RX4OQ8"} />
              </div>
            )}
          </div>
        </div>
        {slide1 ? (
          <img src={Shape5} alt="" className="shape--aboutme2" />
        ) : (
          <img
            src={Shape5}
            alt=""
            className="shape--aboutme2 shape--inverted"
          />
        )}
      </section>
      <section id="courses">
        <img
          src={Shape5}
          alt=""
          className="shape--aboutme2 shape__courses--2 shape--inverted shape-top"
        />
        <div className="row">
          <div className="courses__container">
            <h1 className="courses__title">CURSOS DESTACADOS</h1>
              <div className="courses__imgs--container">
                <div className="course__1">
                  <h2 className="courses__img--title-1">
                    Geometría Descriptiva desde Cero con AutoCAD
                  </h2>
                  <div className="courses__img--wrapper">
                    <img src={Course1} alt="" className="courses__img" />
                    <div className="img__backdrop">
                      <h2 className="img__backdrop--text">
                        ¡Aprende cómo matricularte aquí! <br /> ⬇
                      </h2>
                      <Link
                        to={"/courses/descriptive"}
                        className="img__backdrop--btn"
                      >
                        Más Información
                      </Link>
                    </div>
                    <div className="course__onSale">
                      ¡EN OFERTA!
                    </div>
                  </div>
                  <Link
                      to="/courses/descriptive"
                      className="courses__information--btn"
                    >
                      Más Información
                    </Link>
                </div>
                <div className="course__2">
                  <h2 className="courses__img--title-2">
                    Dibujo En Ingeniería con AutoCAD e Inventor
                  </h2>
                  <div className="courses__img--wrapper">
                    <img src={Course2} alt="" className="courses__img" />
                    <div className="img__backdrop">
                      <h2 className="img__backdrop--text">
                        ¡Obtén más información del próximo curso!
                      </h2>
                      <Link
                        to={"/courses/technicaldrawing"}
                        className="img__backdrop--btn"
                      >
                        Más Información
                      </Link>
                    </div>
                  </div>
                  <Link
                  to="/courses/technicaldrawing"
                  className="courses__information--btn"
                >
                  Más Información
                </Link>
                </div>
              </div>
          </div>
        </div>
        <figure className="courses__shape--wrapper">
          <img
            src={Shape5}
            alt=""
            className="shape--aboutme2 shape__courses--2 shape--inverted"
          />
          <img src={Shape2} alt="" className="courses__shape" />
        </figure>
      </section>
      <section id="features">
        <div className="row">
          <div className="features__container">
            <h2 className="features__title">¿QUÉ OFRECEMOS?</h2>
            <div className="features">
              <div className="feature">
                <div className="feature__description">
                  <MenuBookIcon />
                  <h4 className="feature__title">DIDÁCTICO</h4>
                  <p className="feature__text">
                    El material más didáctico y completo que vas a poder
                    encontrar.
                  </p>
                </div>
              </div>
              <div className="feature">
                <div className="feature__description">
                  <OnlinePredictionIcon />
                  <h4 className="feature__title">ONLINE</h4>
                  <p className="feature__text">
                    ¡Clases completamente virtuales! <br /> Acceso a través de
                    la plataforma Zoom.
                  </p>
                </div>
              </div>
              <div className="feature">
                <div className="feature__description">
                  <AddShoppingCartIcon />
                  <h4 className="feature__title">COMPRA Y APRENDE</h4>
                  <p className="feature__text">
                    ¡Realiza un solo pago anual y accede inmediatamente a las
                    clases grabadas!
                  </p>
                </div>
              </div>
              <div className="feature">
                <div className="feature__description">
                  <CloudSyncIcon />
                  <h4 className="feature__title">PROGRESA A TU RITMO</h4>
                  <p className="feature__text">
                    Retoma las clases en cualquier momento desde nuestra aula
                    virtual
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
