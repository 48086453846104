import React from "react";
import "./MaintenancePage.css";
import Background from "../assets/MaintenanceBG.jpg";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";

function MaintenancePage() {
  return (
    <div>
      <img src={Background} alt="" className="maintenance__bg" />
      <div className="row">
        <div className="maintenance__container">
          <h3>
            Página en Mantenimiento{" "}
            <SentimentVeryDissatisfiedIcon className=" maintenance__icon" />
          </h3>
          <p>
            ¡Visita nuestras redes para enterarte las últimas noticias de la
            página!
          </p>
          <div className="socials">
            <a
              href="https://www.facebook.com/geometriadescriptivaydibujoeningenieriauni"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <FacebookIcon className="footer__icon maintenance__social" />
            </a>
            <a
              href="https://www.youtube.com/@daniellinaresq1872/videos"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <YouTubeIcon className="footer__icon maintenance__social" />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=51921417139"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <WhatsAppIcon className="footer__icon maintenance__social" />
            </a>
            <a
              href="https://www.instagram.com/geodesuni/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <InstagramIcon className="footer__icon maintenance__social" />
            </a>
          </div>
          <Link to="/">
            <button className="nav__link--btn maintenance__btn">
              Volver a Inicio
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MaintenancePage;
