const subject1 = {
  "CLASE 1": "Construcciones Geométricas, Escala e instrumentos de dibujo y comandos de AutoCAD I.",
  "CLASE 2": "Tangencia, dibujo de cónicas y comandos de AutoCAD II.",
  "CLASE 3": "Teoría de Proyección Ortogonal, proyección de puntos en los planos principales de proyección.",
  "CLASE 4": "Practica de Proyección de Punto, desarrollo y solución de ejercicios propuestos.",
  "CLASE 5": "Visibilidad de Tuberías, tuberías que se cruzan en el espacio.",
  "CLASE 6": "Teoría de Proyección de sólido, proyección principal de un sólido con caras oblicuas, rampas y túneles.",
  "CLASE 7": "Practica de Proyección de sólido, proyecciones principales, auxiliares y dibujo isométrico de un sólido.",
  "CLASE 8": "Teoría de Proyección de una recta, orientación, inclinación, pendiente, VM de una recta y tipos de rectas.",
  "CLASE 9": "Practica de Proyección de recta, desarrollo y solución de ejercicios propuestos.",
  "CLASE 10": "Teoría de Proyección de un plano, orientación, inclinación, RMP, pendiente, VM y tipos de planos.",
  "CLASE 11": "Practica de Proyección de plano, desarrollo y solución de ejercicios propuestos.",
  "CLASE 12": "Intersección de planos y rectas, aplicación del método PC, método de la vista de canto y visibilidad.",
  "CLASE 13": "Sombras, sombras de una recta sobre rampas y escalones, sombras de sólidos sobre superficies.",
  "CLASE 14": "Teoría de Paralelismo y Perpendicularidad, Planos Paralelos y recta perpendicular a un plano.",
  "CLASE 15": "Practica de Paralelismo y Perpendicularidad, desarrollo de ejercicios propuestos.",
  "CLASE 16": "Distancia mínima entre rectas que se cruzan, aplicación del método de la recta y el plano.",
  "CLASE 17": "Ángulos, ángulo entre rectas que se cruzan, ángulo entre plano-recta y ángulo entre planos.",
  "CLASE 18": "Giros, giro de un punto, giro de una recta para obtener su VM y giro de un plano para obtener su VM.",
  "CLASE 19": "Intersección de Recta y plano con poliedro, aplicación del método del PC, visibilidad y Tabla.",
  "CLASE 20": "Intersección de Poliedros, aplicación del método del PC, visibilidad y Tabla.",
};

const subject2 = {
  "CLASE 1": "COMANDOS BASICOS DE AUTOCAD, estudio del entorno del programa AutoCAD tanto 2D como 3D, sus elementos, comandos de dibujo y estudio del “layout”.",
  "CLASE 2": "DIBUJO DE FIGURAS Y PIEZAS EN 2D, creación de polígonos, dibujo de figuras complejas, uso de los comandos de tangencia y estudio de escalas.",
  "CLASE 3": "VISTAS DE UN SOLIDO (Proyección Ortogonal), estudio del sistema de proyección ortogonal ISO-A e ISO-E y dibujo de las vistas de un sólido H, F y P.",
  "CLASE 4": "PROYECCION ISOMETRICA DE UN SOLIDO, dibujo isométrico de un sólido a partir de sus 3 vistas H, F y P usando comandos como “Isodraft” y “Elipse”.",
  "CLASE 5": "CONSTRUCCION DE SOLIDOS EN 3D, dibujo de sólidos y piezas mecánicas utilizando comandos de modelamiento y de Edición de Solidos.",
  "CLASE 6": "CONSTRUCCION DE SUPERFICIES DE REVOLUCION EN 3D, dibujo de superficies cónicas, cilíndricas y esféricas con uso del comando “Revolve”, así como uso de edición y modificación.",
  "CLASE 7": "GENERAR VISTAS E ISOMETRICO DE UNA PIEZA I, creación de las vistas de un solido o superficie en el sistema ISO-A e ISO-E, uso de escalas, cotas y posiciones.",
  "CLASE 8": "GENERAR VISTAS E ISOMETRICO DE UNA PIEZA II, creación de membrete y marcos, estudio de líneas visibles e invisibles, exportación y “plot” de un archivo dwg.",
  "CLASE 9": "GENERAR CORTES Y SECCIONES DE UNA PIEZA, uso del comando “Section”, tipos de secciones de un sólido, uso del comando “Hatch” y posiciones de la sección.",
  "CLASE 10": "CONSTRUCCION DE PIEZAS MECANICAS E INDUSTRIALES, dibujo de sólidos compuestos por varias piezas como: tuercas, tornillos, soportes, bases, llaves y más.",
  "CLASE 11": "DIBUJO DE SOLIDOS EN INVENTOR, uso del programa Inventor para la creación de sólidos, estudio del entorno del programa, elementos y comandos de dibujo.",
  "CLASE 12": "GENERAR LAS VISTAS DE UN SOLIDO EN INVENTOR, creación de las vistas en sistema ISO-A e ISO-E de un sólido dibujado en el programa Inventor, así como el membrete y marco."
}


export {subject1, subject2}
