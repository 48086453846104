import React, { useEffect, useState } from "react";
import "./UserDashboard.css";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db, storage } from "../firebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import DashboardBG from "../assets/HomeBG2.jpeg";
import userBlank from "../assets/blank_user.png";
import Course1 from "../assets/HomeCourse1.jpeg";
import Course2 from "../assets/HomeCourse2.jpg";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import {
  collection,
  doc,
  where,
  query,
  getDocs,
  updateDoc,
} from "firebase/firestore";

function UserDashboard() {
  const [user, setUser] = useState("");
  const [userData, setUserData] = useState("");
  const [userDocId, setUserDocId] = useState("");
  const navigate = useNavigate();
  const profilePicturesRef = ref(storage, `profile-pictures/${user.uid}`);

  const [newName, setNewName] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        getDownloadURL(ref(storage, `profile-pictures/${user.uid}`))
          .then((url) => {
            const profilePictureEl = document.querySelector(".user__img");
            profilePictureEl.setAttribute("src", url);
          })
          .catch((error) => {
            console.log('Archivo Inexistente');
          });
      } else {
        navigate("/login");
      }
    });
    fetchUserData();
  }, [user]);

  function LogOut() {
    auth.signOut();
  }

  function clickImage() {
    const input = document.querySelector(".dashboard__input");
    input.click();
  }

  function handleSubmitPicture(e) {
    uploadBytes(profilePicturesRef, e.target.files[0]).then((snapshot) => {
      console.log("Imagen subida al sistema satisfactoriamente");
    });

    getDownloadURL(ref(storage, `profile-pictures/${user.uid}`))
      .then((url) => {
        const profilePictureEl = document.querySelector(".user__img");
        profilePictureEl.setAttribute("src", url);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchUserData();
  }

  function handleEdit() {
    const backdropEl = document.querySelector(".dashboard__backdrop");
    if (backdropEl.classList.contains("dashboard__backdrop--active")) {
      backdropEl.classList.remove("dashboard__backdrop--active");
    } else {
      backdropEl.classList += " dashboard__backdrop--active";
    }
  }

  async function fetchUserData() {
    const queryUser = query(
      collection(db, "users"),
      where("uid", "==", `${user.uid}`)
    );

    const snapshots = await getDocs(queryUser);
    setUserDocId(snapshots.docs[0].id);

    const docs = snapshots.docs.map((doc) => doc.data());

    setUserData(docs[0]);

    if (docs[0].photoURL) {
      const profilePictureEl = document.querySelector(".user__img");
      profilePictureEl.setAttribute("src", userData.photoURL);
    }
  }

  function handleSubmitNewData() {
    const userRef = doc(db, "users", `${userDocId}`);
    updateDoc(userRef, { nombre: newName })
      .then(() => {
        console.log("Nombre editado satisfactoriamente");
        fetchUserData();
      })
      .catch((error) => {
        console.log("Error al editar: ", error);
      });

    handleEdit();
  }

  return (
    <div id="dashboard">
      <div className="dashboard__backdrop">
        <div className="dashboard__backdrop--container">
          <p className="user__edit">
            <span className="user__edit" onClick={handleEdit}>
              Cancelar
              <CloseIcon className="user__edit--icon" />
            </span>
          </p>
          <p>Introduzca Nuevo Nombre</p>
          <input
            type="text"
            placeholder="Nuevo Nombre"
            onChange={(e) => setNewName(e.target.value)}
          />
          <button
            className="dashboard__btn dashboard__backdrop--btn"
            onClick={handleSubmitNewData}
          >
            Enviar
          </button>
        </div>
      </div>
      <img src={DashboardBG} alt="" className="dashboard__bg" />
      <div className="row">
        <div className="dashboard__container">
          <h2>DASHBOARD</h2>
          <div className="dashboard__user-information">
            <div className="img__container">
              <figure className="user__img--wrapper" onClick={clickImage}>
                <img
                  src={userData.photoURL || userBlank}
                  alt="User Profile"
                  className="user__img"
                />
                <input
                  type="file"
                  onChange={(e) => handleSubmitPicture(e)}
                  className="dashboard__input"
                />{" "}
              </figure>
            </div>
            <div className="dashboard__user-description">
              <p className="user__edit">
                <span className="user__edit" onClick={handleEdit}>
                  Cambiar Datos &nbsp;
                  <SettingsIcon className="user__edit--icon" />
                </span>
              </p>
              <h3>Nombre: {userData?.nombre || "Usuario Nuevo"}</h3>
              <h3>Correo electrónico: {user.email}</h3>
              <h4 style={{ marginTop: "12px" }}>
                Tu ID de usuario es: {user.uid}
              </h4>
              <p style={{ fontSize: "14px", color: "gray" }}>
                Si tienes algún problema comunícate con soporte haciéndole
                llegar tu ID de usuario.
              </p>
              <button onClick={LogOut} className="dashboard__btn">
                Cerrar Sesión
              </button>
            </div>
          </div>
          <h3>Mis Cursos</h3>
          <div className="dashboard__courses">
            <div className="dashboard__course">
              <img src={Course1} alt="" className="dashboard__course--img" />
              {userData?.curso1 ? (
                <Link to="/classroom/descriptive/home">
                  <button className="dashboard__course--btn">
                    Ir al Curso
                  </button>
                </Link>
              ) : (
                <Link to="/courses/descriptive">
                  <button className="dashboard__course--btn">Comprar</button>
                </Link>
              )}
            </div>
            <div className="dashboard__course">
              <img src={Course2} alt="" className="dashboard__course--img" />
              {userData?.curso2 ? (
                <Link to="/classroom/technicaldrawing/home">
                <button className="dashboard__course--btn">Ir al Curso</button>
              </Link>
              ) : (
                <Link to="/courses/technicaldrawing">
                <button className="dashboard__course--btn">Comprar</button>
              </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
