import React, { useState } from "react";
import "./PopUp.css";
import Course1 from "../assets/HomeCourse1.jpeg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

function PopUp() {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  function handleClosePopUp() {
    setIsOpen(false);
    navigate("/courses/descriptive");
  }

  return (
    <>
      {isOpen && (
        <div id="popUp">
          <div className="popUp__container">
            <h2>¡Inicio de Clases 17 de Junio!</h2>
            <h3>INSCRÍBETE AHORA</h3>
            <div className="popUp__img-description">
              <div className="popUp__img--wrapper">
                <img src={Course1} alt="" className="popUp__img" />
                <div className="popUp__onSale">¡EN OFERTA!</div>
              </div>
              <div className="popUp__description">
                <div className="popUp__description--border">
                </div>
                <h1>GEOMETRÍA DESCRIPTIVA desde CERO</h1>
                <h2>¡Precio de promoción hasta el 16 de Junio!</h2>
                <h2>20 Clases Teóricas y Prácticas (40 Horas)</h2>
                <h3>
                  {" "}
                  <span style={{ fontWeight: "700", color: "gold" }}>
                    S/. 160.00
                  </span>
                  <br />
                  <p style={{ fontSize: "32px", display:"flex", justifyContent: "center", marginTop:"-10px"}}>($ 45.00)</p>
                </h3>
                {/* <p
                  style={{ color: "lightgray", textDecoration: "line-through" }}
                >
                  Antes: S/. 240.00
                </p> */}
                <button className="popUp__btn" onClick={handleClosePopUp}>
                  IR AL CURSO
                </button>
              </div>
            </div>
            <div className="close--popUp" onClick={() => setIsOpen(false)}>
              <span className="popUp__close-icon close__text">Cerrar</span>
              <CloseIcon className="popUp__close-icon" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PopUp;
