import React, { useEffect, useState } from "react";
import ExamBG from "../assets/ExamBG.jpg";
import "./ClassroomExam.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebaseConfig";

function ClassroomExam({course}) {
    const [pdfURL, setPdfURL] = useState("");
    const [dwgURL, setDwgURL] = useState("");

    const [pdf, setPdf] = useState(false);
    const [dwg, setDwg] = useState(false);

    const pdfRef = ref(
        storage,
        `descriptive-geometry-course/exam/EXAMEN DE GEOMETRIA DESCRIPTIVA -GeoDes.pdf`
      );
      const dwgRef = ref(
        storage,
        `descriptive-geometry-course/exam/EXAMEN - GEOMETRIA DESCRIPTIVA - GeoDes.dwg`
      );

      useEffect(() => {
        getFilesData();
      }, [course]);

      function getFilesData() {
        getDownloadURL(pdfRef)
          .then((url) => {
            if (url) {
              setPdfURL(url);
              setPdf(true);
            }
          })
          .catch((e) => {
            console.log(e);
            setPdf(false);
          });
    
        getDownloadURL(dwgRef)
          .then((url) => {
            if (url) {
              setDwgURL(url);
              setDwg(true);
            }
          })
          .catch((e) => {
            console.log(e);
            setDwg(false);
          });
      }

  return (
    <div className="classroom__content">
      <img src={ExamBG} alt="" className="classroom__content--bg" />
      <div className="classroom__content--container">
        <div className="classroom__content--box">
          <h2 style={{ color: "#192b90", fontSize: "42px" }}>
            ¡FELICIDADES POR COMPLETAR EL CURSO!
          </h2>
          <p style={{ marginTop: "32px", fontSize: "18px" }}>
            Estás a un paso de lograr la certificación en{" "}
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              Geometría Descriptiva desde Cero
            </span>
          </p>
          <p style={{ marginTop: "32px", fontSize: "18px" }}>
            Descarga los archivos siguientes y resuelve el examen final del
            curso, <br /> luego envía tu examen al correo{" "}
            <span
              style={{ color: "#192b90", fontSize: "22px", fontWeight: "600" }}
            >
              carlosdlq260@gmail.com
            </span> y espera la respuesta en unos días.
          </p>
          <div className="class__downloads" style={{width:"100%"}}>
            {pdf && <a href={pdfURL} download={`ExamenPdf`}>
              <div className="class__download class__classPDF">
                <PictureAsPdfIcon />
                <p>Descarga Aquí el PDF del Examen</p>
              </div>
            </a>}
            {dwg && <a href={dwgURL} download={`ExamenDwg`}>
              <div className="class__download class__classDWG">
                <FilePresentIcon />
                <p>Descarga Aquí el archivo DWG del Examen</p>
              </div>
            </a>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassroomExam;
