import "./ContactUs.css";
import React, { useRef } from "react";
import ContactBG from "../assets/ContactBG.jpg";
import ContactPhoto from "../assets/ContactPhoto.jpeg";
import emailjs from "@emailjs/browser";

function ContactUs() {
  const form = useRef();

  function handleSubmit(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_mrtgvwm",
        "template_3dy0hhq",
        form.current,
        "9P2dgMg1X_3uWgiYS"
      )
      .then(
        (result) => {
          let inputsEl = document.querySelectorAll(".form__input");
          inputsEl.forEach((elem) => {
            elem.value = "";
          });
          let contactBtnEl = document.querySelector(".contact__btn");
          contactBtnEl.classList += " form-sended";
          contactBtnEl.innerHTML = "Enviado";
        },
        (error) => {
          alert("Ocurrió un error durante el envío, Inténtelo más tarde");
        }
      );
  }

  return (
    <section id="contact">
      <figure className="contact__background--wrapper">
        <img src={ContactBG} alt="" className="contact__background" />
      </figure>
      <div className="row">
        <div className="contact__container">
          <div className="contact__header">
            <h3>¿Necesitas Asesorías Personales?</h3>
            <h4>¡No dudes en conversar con el profesor Daniel Linares!</h4>
            <p>
              Rellena el formulario y recibe una respuesta del profesor lo antes
              posible, ¡No te quedes sin tus asesorías privadas!
            </p>
          </div>
          <div className="contact__form-section">
            <form className="contact__form" ref={form}>
              <div>
                <label>
                  Nombres <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Nombres"
                  name="user_name"
                  className="form__input"
                  required
                />
              </div>
              <div>
                <label>
                  Apellidos <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Apellidos"
                  name="user_lastname"
                  className="form__input"
                  required
                />
              </div>
              <div>
                <label>
                  Correo <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Correo Electrónico"
                  name="user_email"
                  className="form__input"
                  required
                />
              </div>
              <div>
                <label>
                  Móvil <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Número de celular"
                  name="user__mobile"
                  className="form__input"
                  required
                />
              </div>
              <div>
                <label>
                  Mensaje <span>*</span>
                </label>
                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Escribe aquí tu mensaje..."
                  className="form__input form__text-area"
                  required
                ></textarea>
              </div>
              <button className="contact__btn" onClick={(e) => handleSubmit(e)}>
                Recibir Asesoría
              </button>
            </form>
            <p>
              El profesor Linares está dispuesto a ayudarte, ¡No dudes en
              escribirle!
            </p>
            <figure className="contact__img--wrapper">
              <img src={ContactPhoto} alt="" className="contact__img" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
