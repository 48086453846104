import { ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { storage } from "../firebaseConfig";
import AdminPageClassesEdit from "./AdminPageClassesEdit";
import "./AdminPageContent.css";
import AdminPageUsers from "./AdminPageUsers";

function AdminPageContent({ content }) {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedClass, setSelectedClass] = useState("class1");
  const [typeOfFile, setTypeOfFile] = useState("");
  const [typeOfFileOutput, setTypeOfFileOutput] = useState("");
  const [activeFile, setActiveFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    setSelectedCourse('')
  }, [content])

  function handleClickFileUpload(type, typeDestiny) {
    setTypeOfFile(type);
    setTypeOfFileOutput(typeDestiny);
    setActiveFile(null);
    setUploadProgress(0);
    const uploadingMessage = document.querySelector(".uploading__message");
    uploadingMessage.innerHTML = "";
    const inputEl = document.querySelector(".upload__input");
    inputEl.click();
    const errorMessage = document.querySelector(".upload__error-message");
    errorMessage.innerHTML = "";
  }

  function resetFile() {
    setActiveFile(null);
    const inputEl = document.querySelector(".upload__input");
    inputEl.value = null;
    setUploadProgress(0);
    const uploadingMessage = document.querySelector(".uploading__message");
    uploadingMessage.innerHTML = "";
  }

  function uploadFile(file) {
    let typeOfFileUploaded = file.name.substring(file.name.length - 3);
    let fileNameRef = "";

    switch (typeOfFileOutput) {
      case "video":
        fileNameRef = `${selectedClass}Video.mp4`;
        break;
      case "pdf":
        fileNameRef = `${selectedClass}Pdf.pdf`;
        break;
      case "dwg":
        fileNameRef = `${selectedClass}Dwg.dwg`;
        break;
      default:
        break;
    }

    if (typeOfFile === typeOfFileUploaded) {
      const errorMessage = document.querySelector(".upload__error-message");
      errorMessage.innerHTML = "";
      const uploadingMessage = document.querySelector(".uploading__message");
      uploadingMessage.innerHTML = "Subiendo...";

      let fileRef;

      if (selectedCourse === "descriptive") {
        fileRef = ref(
          storage,
          `descriptive-geometry-course/${selectedClass}/${fileNameRef}`
        );
      }

      if (selectedCourse === "technicaldrawing") {
        fileRef = ref(
          storage,
          `technical-drawing/${selectedClass}/${fileNameRef}`
        );
      }

      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          const errorMessage = document.querySelector(".upload__error-message");
          errorMessage.innerHTML =
            "Ocurrió un error durante la subida, inténtalo de nuevo más tarde.";
        },
        () => {
          const uploadingMessage = document.querySelector(
            ".uploading__message"
          );
          uploadingMessage.innerHTML = "Se subió satisfactoriamente";
        }
      );
    } else {
      const uploadingMessage = document.querySelector(".uploading__message");
      uploadingMessage.innerHTML = "";
      const errorMessage = document.querySelector(".upload__error-message");
      errorMessage.innerHTML =
        "No coincide el formato de entrada con el archivo subido";
    }
  }

  function contentHtml() {
    switch (content) {
      case "upload":
        return (
          <>
            <h1>Subir archivos</h1>
            <div style={{ marginTop: "12px" }}>
              <label htmlFor="class" style={{ marginBottom: "8px" }}>
                Elige el curso:
              </label>
              <select
                name="class"
                id="class"
                onChange={(e) => setSelectedCourse(e.target.value)}
                defaultValue="class1"
              >
                <option value="" defaultChecked></option>
                <option value="descriptive">
                  Geometría Descriptiva desde Cero
                </option>
                <option value="technicaldrawing">Dibujo en Ingeniería</option>
              </select>
            </div>
            {selectedCourse && (
              <div className="upload__class">
                <label htmlFor="class" style={{ marginBottom: "8px" }}>
                  Elige la clase:
                </label>
                <select
                  name="class"
                  id="class"
                  onChange={(e) => setSelectedClass(e.target.value)}
                  defaultValue="class1"
                >
                  {selectedCourse === "descriptive" &&
                    new Array(20).fill(0).map((_, i) => (
                      <option value={`class${i + 1}`} key={i}>
                        Clase {i + 1}
                      </option>
                    ))}
                  {selectedCourse === "technicaldrawing" &&
                    new Array(15).fill(0).map((_, i) => (
                      <option value={`class${i + 1}`} key={i}>
                        Clase {i + 1}
                      </option>
                    ))}
                </select>
                <p
                  className="upload__error-message"
                  style={{ marginTop: "16px", color: "red" }}
                ></p>
                <div className="upload__class--btns">
                  <input
                    type="file"
                    onChange={(e) => setActiveFile(e.target.files[0])}
                    className="upload__input"
                    style={{ display: "none" }}
                  />
                  <button onClick={() => handleClickFileUpload("mp4", "video")}>
                    Elegir Video
                  </button>
                  <button
                    onClick={() => handleClickFileUpload("pdf", "pdf")}
                    style={{ width: "180px" }}
                  >
                    Elegir Pdf
                  </button>
                  <button
                    onClick={() => handleClickFileUpload("dwg", "dwg")}
                    style={{ width: "180px" }}
                  >
                    Elegir Dwg
                  </button>
                </div>
                {activeFile && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "12px",
                    }}
                  >
                    <p>
                      Archivo Actual: {activeFile.name} <br /> Tipo de Archivo:{" "}
                      {activeFile.type !== ""
                        ? activeFile.type
                        : activeFile.name.substring(
                            activeFile.name.length - 3
                          )}{" "}
                    </p>
                    <button
                      onClick={resetFile}
                      style={{
                        width: "200px",
                        marginTop: "16px",
                        padding: "4px 8px",
                      }}
                    >
                      Resetear Archivo
                    </button>
                    <button
                      className="upload__btn"
                      onClick={() => uploadFile(activeFile)}
                    >
                      SUBIR
                    </button>
                  </div>
                )}
                <p
                  className="uploading__message"
                  style={{ marginTop: "16px", color: "#192b80" }}
                ></p>
                {uploadProgress !== 0 && (
                  <p>Progreso de la subida: {uploadProgress}%</p>
                )}
              </div>
            )}
          </>
        );
      case "users":
        return <AdminPageUsers />;
      case "classes":
        return <AdminPageClassesEdit />;
      default:
        break;
    }
  }

  return <>{contentHtml()}</>;
}

export default AdminPageContent;
