import React, { useEffect } from "react";
import { useState } from "react";
import AdminPageContent from "../components/AdminPageContent";
import "./AdminPage.css";

function AdminPage() {
  const [admin, setAdmin] = useState({});
  const [login, setLogin] = useState(false);
  const [content, setContent] = useState("upload");

  useEffect(() => {
    admin.user = localStorage.getItem("user");
    admin.password = localStorage.getItem("password");
    handleLoginSubmit();
  }, []);

  function handleLoginSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    const errorMessageEl = document.querySelector(".admin__login--error");

    if (admin.user === "geodes" && admin.password === "carlos123") {
      setLogin(true);
      localStorage.setItem("user", "geodes");
      localStorage.setItem("password", "carlos123");
    } else if (Object.keys(admin).length === 0) {
      errorMessageEl.innerHTML = "Ingrese un usuario";
    } else {
      errorMessageEl.innerHTML = "Usuario incorrecto";
    }
  }

  function logOut() {
    localStorage.setItem("user", "");
    localStorage.setItem("password", "");
    setLogin(false)
  }

  return (
    <div id="admin">
      {!login ? (
        <div className="admin__login">
          Usuario:{" "}
          <input
            type="text"
            onChange={(e) => setAdmin({ ...admin, user: e.target.value })}
          />{" "}
          <br />
          Contraseña:{" "}
          <input
            type="password"
            onChange={(e) => setAdmin({ ...admin, password: e.target.value })}
          />
          <p
            style={{ color: "red", marginTop: "4px", fontSize: "14px" }}
            className="admin__login--error"
          ></p>
          <button onClick={handleLoginSubmit} style={{ marginTop: "4px" }}>
            {" "}
            Ingresar
          </button>
        </div>
      ) : (
        <div className="admin__container">
          <button
            style={{ color: "black", width: "200px", margin: "0 auto" }}
            onClick={logOut}
          >
            Cerrar Sesión
          </button>
          <h2>Menú Administrador</h2>
          <div className="admin__menus">
            <button onClick={() => setContent("upload")}>
              Subir Archivos/Video por Clase
            </button>
            <button onClick={() => setContent("users")}>
              Gestionar Usuarios
            </button>
            <button onClick={() => setContent("classes")}>
              Estado de Clases
            </button>
          </div>
          <div className="admin__content">
            <AdminPageContent content={content} />
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminPage;
