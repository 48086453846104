import React from "react";
import "./Whatsapp.css";
import WhatsappLogo from "../assets/WhatsappLogo.png";

function Whatsapp() {
  function openWhatsapp() {
    window.open("https://api.whatsapp.com/send?phone=51921417139", '_blank', 'noopener,noreferrer')
  }

  return (
      <figure className="whatsapp__logo--wrapper" onClick={() => openWhatsapp()}>
        <img src={WhatsappLogo} alt="" className="whatsapp__img" />
        <div className="whatsapp__backdrop">¡Conversa con Nosotros!</div>
      </figure>
  );
}

export default Whatsapp;
