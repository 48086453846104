import React from "react";
import { Link } from "react-router-dom";
import "./Nav.css";
import Logo from "../assets/LogoNav.svg";
import Logo2 from "../assets/Recurso 3.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import DownIcon from "../assets/downIcon.png";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebaseConfig";

function Nav() {
  function openMenu() {
    const backdropEl = document.querySelector(".nav__backdrop");
    if (backdropEl.classList.contains("active-menu")) {
      backdropEl.classList.remove("active-menu");
    } else {
      backdropEl.classList += " active-menu";
    }
    logEvent(analytics, "Menu navegacion");
  }

  return (
    <nav>
      <div className="row">
        <div className="container">
          <figure className="img__wrapper">
            <Link to={"/"}>
              <img src={Logo} alt="ja" className="nav__img" />
              <img src={Logo2} alt="ja" className="nav__img nav__logo--2" />
            </Link>
          </figure>
          <div className="nav__links">
            <Link to="/" className="nav__link">
              Inicio
            </Link>
            <div className="nav__link nav__link--backdrop">
              Cursos <img src={DownIcon} alt="" className="nav__down-icon" />
              <div className="nav__courses--backdrop">
                <Link
                  to={"/courses/descriptive"}
                  className="nav__backdrop--link--hover"
                >
                  Geometría Descriptiva desde Cero
                </Link>
                <Link
                  to={"/courses/technicaldrawing"}
                  className="nav__backdrop--link--hover"
                >
                  Dibujo En Ingeniería
                </Link>
              </div>
            </div>
            <Link to="/contact" className="nav__link">
              Contáctenos
            </Link>
            <Link to="/register" className="nav__link">
              Registrarse
            </Link>
          </div>
          <div className="nav__backdrop">
            <CloseIcon className="backdrop__close-icon" onClick={openMenu} />
            <div className="backdrop__links">
              <Link
                to="/"
                className="nav__link backdrop__link"
                onClick={openMenu}
              >
                Inicio
              </Link>
              <Link
                to="/courses/descriptive"
                className="nav__link backdrop__link"
                onClick={openMenu}
              >
                Cursos
              </Link>
              <Link
                to="/contact"
                className="nav__link backdrop__link"
                onClick={openMenu}
              >
                Contáctenos
              </Link>
              <Link
                to="/register"
                className="nav__link backdrop__link"
                onClick={openMenu}
              >
                Registrarse
              </Link>
            </div>
          </div>
          <div className="nav__classroom">
            <MenuIcon id="nav__icon--menu" onClick={openMenu} />
            <Link to="/dashboard" className="nav__link--btn">
              Aula Virtual
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
