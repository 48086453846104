import { getDownloadURL, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { storage } from "../firebaseConfig";
import book1 from "../assets/book1.PNG";
import book2 from "../assets/book2.PNG";
import book4 from "../assets/book4.PNG";
import book5 from "../assets/book5.PNG";
import book6 from "../assets/book6.PNG";
import "./ClassroomBooks.css";

function ClassroomBooks({course}) {
  const fileRef1 = ref(storage, "descriptive-geometry-course/libros/GEOMETRÍA DESCRIPTIVA - DESKREP.pdf");
  const fileRef2 = ref(storage, "descriptive-geometry-course/libros/Geometría Descriptiva - Jorge Nakamura.pdf");
  const fileRef3 = ref(storage, "descriptive-geometry-course/libros/8-ESCALAS-Y-USO-DEL-ESCALÍMETRO.pdf");
  const fileRef4 = ref(storage, "technical-drawing/libros/DIBUJO TECNICO EN AUTOCAD.pdf");
  const fileRef5 = ref(storage, "technical-drawing/libros/GUIA DE DIBUJO EN INVENTOR.pdf");
  const [book1Url, setBook1Url] = useState("");
  const [book2Url, setBook2Url] = useState("");
  const [book3Url, setBook3Url] = useState("");
  const [book4Url, setBook4Url] = useState("");
  const [book5Url, setBook5Url] = useState("");

  useEffect(() => {
    getBooks();
  }, []);

  function getBooks() {
    if (course === "descriptive") {
      console.log('libros descriptiva')
      Promise.all([
        getDownloadURL(fileRef1),
        getDownloadURL(fileRef2),
        getDownloadURL(fileRef3),
      ]).then((urls) => {
          setBook1Url(urls[0]);
          setBook2Url(urls[1]);
          setBook3Url(urls[2]);
      });
    }
    if (course === "technicaldrawing") {
      console.log('libros dibujo')
      Promise.all([
        getDownloadURL(fileRef4),
        getDownloadURL(fileRef1),
        getDownloadURL(fileRef5),
        getDownloadURL(fileRef2),
      ]).then((urls) => {
          setBook1Url(urls[0]);
          setBook2Url(urls[1]);
          setBook4Url(urls[2]);
          setBook5Url(urls[3]);
      });
    }
  }

  return (
    <div className="classroom__books--grid" style={{gridTemplateColumns: course === 'descriptive' ? '240px 240px 240px' : '240px 240px 240px 240px'}}>
      <div className="classroom__book--wrapper">
        <a href={book1Url} target={"_blank"} rel="noreferrer"><img src={book1} alt="Book1" className="classroom__book" /></a>
        <a href={book1Url} download="GEOMETRÍA DESCRIPTIVA - DESKREP" className="book__download--btn">Visualizar</a>
      </div>
      <div className="classroom__book--wrapper">
        <a href={book2Url} target={"_blank"} rel="noreferrer"><img src={book2} alt="Book2" className="classroom__book" /></a>
        <a href={book2Url} download="Geometría Descriptiva - Jorge Nakamura" className="book__download--btn">Visualizar</a>
      </div>
      {course === 'descriptive' && <>
      <div className="classroom__book--wrapper">
        <a href={book3Url} target={"_blank"} rel="noreferrer"><img src={book4} alt="Book3" className="classroom__book" /></a>
        <a href={book3Url} download="8-ESCALAS-Y-USO-DEL-ESCALÍMETRO" className="book__download--btn">Visualizar</a>
      </div>
      </>}
      
      {course === 'technicaldrawing' && <>
        <div className="classroom__book--wrapper">
          <a href={book4Url} target={"_blank"} rel="noreferrer"><img src={book5} alt="Book4" className="classroom__book" /></a>
          <a href={book4Url} download="DIBUJO TECNICO EN AUTOCAD" className="book__download--btn">Visualizar</a>
        </div>
        <div className="classroom__book--wrapper">
          <a href={book5Url} target={"_blank"} rel="noreferrer"><img src={book6} alt="Book5" className="classroom__book" /></a>
          <a href={book5Url} download="GUIA DE DIBUJO EN INVENTOR" className="book__download--btn">Visualizar</a>
       </div>
      </>}
    </div>
  );
}

export default ClassroomBooks;
