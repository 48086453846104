import React, { useEffect } from "react";
import "./ClassDownloads.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebaseConfig.js";
import { Link, useParams } from "react-router-dom";
import ClassroomEmptyVideo from "./ClassroomEmptyVideo";
import axios from "axios";

function ClassDownloads({ course }) {
  const { classNum } = useParams();
  const [pdfURL, setPdfURL] = useState("");
  const [dwgURL, setDwgURL] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const [pdf, setPdf] = useState(false);
  const [dwg, setDwg] = useState(false);
  const [video, setVideo] = useState(false);

  let classUrl;
  switch (course) {
    case "descriptive":
      classUrl = "descriptive-geometry-course";
      break;
    case "technicaldrawing":
      classUrl = "technical-drawing";
      break;
    default:
      break;
  }

  let pdfRef;
  let dwgRef;

  if (course === "descriptive") {
    pdfRef = ref(storage, `/${classUrl}/${classNum}/${classNum}Pdf.pdf`);
    dwgRef = ref(storage, `/${classUrl}/${classNum}/${classNum}Dwg.dwg`);
  } else {
    pdfRef = ref(storage, `/${classUrl}/${classNum}/${classNum}Pdf.pdf`);
    dwgRef = ref(storage, `/${classUrl}/${classNum}/${classNum}Dwg.dwg`);
  }

  const videoRef = ref(
    storage,
    `/${classUrl}/${classNum}/${classNum}Video.mp4`
  );

  useEffect(() => {
    getFilesData();
    setIsDownloading(false);
  }, [classNum]);

  function getFilesData() {
    getDownloadURL(pdfRef)
      .then((url) => {
        if (url) {
          setPdfURL(url);
          setPdf(true);
        }
      })
      .catch((e) => {
        console.log(e);
        setPdf(false);
      });

    getDownloadURL(dwgRef)
      .then((url) => {
        if (url) {
          setDwgURL(url);
          setDwg(true);
        }
      })
      .catch((e) => {
        console.log(e);
        setDwg(false);
      });

    getDownloadURL(videoRef)
      .then((url) => {
        if (url) {
          setVideoURL(url);
          setVideo(true);
        }
      })
      .catch((e) => {
        console.log(e);
        setVideo(false);
      });
  }

  function downloadVideo() {
    if (isDownloading) {
      return;
    }
    setIsDownloading(true);
    axios({
      url: videoURL,
      method: "GET",
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setDownloadProgress(progress);
      },
    })
      .then((response) => {
        setIsDownloading(false);
        setDownloadProgress(0);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        // Definir el nombre del curso basado en el valor de `course`
        let courseName;
        if (course === "technicaldrawing") {
          courseName = "Dibujo Técnico";
        } else if (course === "descriptive") {
          courseName = "Geometría Descriptiva";
        }


        a.download = `GeoDesUNI - Clase ${classNum.slice(
          5
        )} - ${courseName} - Video.mp4`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => {
        setIsDownloading(false);
        setDownloadProgress(0);
        console.error("Error al descargar el video:", error);
      });
  }

  return (
    <>
      {video ? (
        <video
          width="640"
          height="360"
          controls
          controlsList="nodownload"
          autoPlay={false}
          src={videoURL}
          style={{ marginTop: "24px" }}
        />
      ) : (
        <ClassroomEmptyVideo />
      )}
      <div className="class__downloads">
        {pdf && (
          <a href={pdfURL} download={`Class${classNum}Pdf`}>
            <div className="class__download class__classPDF">
              <PictureAsPdfIcon />
              <p>Descarga Aquí el PDF de la clase</p>
            </div>
          </a>
        )}
        {dwg && (
          <a href={dwgURL} download={`Class${classNum}Dwg`}>
            <div className="class__download class__classDWG">
              <FilePresentIcon />
              <p>Descarga Aquí el archivo DWG de la clase</p>
            </div>
          </a>
        )}
        {video && course && (
          <div
            className="class__download class__classVideo"
            onClick={downloadVideo}
          >
            {isDownloading ? (
              <div>
                <div>
                  Descargando video...<span>{downloadProgress}%</span>
                </div>

                <div
                  style={{
                    width: `${downloadProgress}%`,
                    height: "20px",
                    backgroundColor: "green",
                  }}
                />
              </div>
            ) : (
              <>
                <FilePresentIcon />
                <p>Descarga Aquí el video de la clase</p>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default ClassDownloads;
