import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import LogIn from "./pages/LogIn";
import Courses from "./pages/Courses";
import Register from "./pages/Register";
import Classroom from "./pages/Classroom";
import NavFootLayout from "./pages/NavFootLayout";
import UserDashboard from "./pages/UserDashboard";
import MaintenancePage from "./pages/MaintenancePage";
import ContactUs from "./pages/ContactUs";
import Course1 from "./pages/Course1/Course1";
import Course2 from "./pages/Course1/Course2";
import ScrollToTop from "./helpers/ScrollToTop";
import AdminPage from "./pages/AdminPage";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route element={<NavFootLayout />}>
            <Route path="/" element={<Home />} />
            {/* <Route path="/courses" element={<Courses />} /> */}
            <Route exact path="/courses/descriptive" element={<Course1 />} />
            <Route
              exact
              path="/courses/technicaldrawing"
              element={<Course2 />}
            />
            <Route path="/contact" element={<ContactUs />} />
          </Route>
          <Route path="/login" element={<LogIn />} />
          <Route path="/register" element={<Register course="descriptive"/>} />
          <Route path="/classroom/descriptive" element={<Classroom course="descriptive"/>}>
            <Route exact path="/classroom/descriptive/:classNum" element={<Classroom course="descriptive"/>}/>
          </Route>
          <Route path="/classroom/technicaldrawing" element={<Classroom course="technicaldrawing" />}>
            <Route exact path="/classroom/technicaldrawing/:classNum" element={<Classroom course="technicaldrawing"/>}/>
          </Route>

          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/admin" element={<AdminPage />} />

          {/* <Route path="/login" element={<MaintenancePage />} />
          <Route path="/register" element={<MaintenancePage />} />
          <Route path="/classroom" element={<MaintenancePage />} />
          <Route path="/dashboard" element={<MaintenancePage />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
