import React, { useState } from "react";
import "./LogIn.css";
import Background from "../assets/LogInBG.jpg";
import { Link, useNavigate } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, db, provider } from "../firebaseConfig";
import GoogleLogo from "../assets/GoogleLogo.svg";
import { addDoc, collection, getDocs, query, serverTimestamp, where } from "firebase/firestore";

function LogIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate()

  function inputEmail(e) {
    setEmail(e.target.value);
  }

  function inputPassword(e) {
    setPassword(e.target.value);
  }

  function LogInUser(e) {
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        navigate("/dashboard")
      })
      .catch((error) => {
        alert('Credenciales erróneas. Ingrese nuevamente');
      });
  }

  function LogInGoogle () {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;

        return user
      }).then(async function (user) {
        const checkExists = await checkIfUserExistsInFirestore(user.email)

        if (!checkExists) {
          addUserFirestore(user);
         }

        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error)
      });
  }

  function addUserFirestore(user) {
    addDoc(collection(db, "users"), {
      email: user.email,
      uid: user.uid,
      timestamp: serverTimestamp(),
      curso1: false,
      nombre: user.displayName || `Invitado@${Math.floor(Math.random()*10000)}`,
      photoURL: user.photoURL || null,
    });
    setEmail("");
    setPassword("");
  }

  async function checkIfUserExistsInFirestore(email) {
    const queryUser = query(
      collection(db, "users"),
      where("email", "==", email)
    );

    const snapshots = await getDocs(queryUser);

    return !snapshots.empty
  }

  return (
    <div id="login">
      <figure className="login__img--wrapper">
        <img src={Background} alt="" className="login__img" />
      </figure>
      <div className="login__container">
        <div className="login__return">
          <Link to={"/"} className="login__return--anchor">
            <KeyboardDoubleArrowLeftIcon className="login__return-icon" />{" "}
            <span className="login__return--text">Inicio</span>
          </Link>
        </div>
        <hr />
        <h2 className="login__text">Inicia Sesión</h2>
        <form onSubmit={(e) => LogInUser(e)} style={{display:"flex", flexDirection:"column"}}>
          <label style={{marginBottom:"12px"}}>
            Correo Electrónico: <br/>
            <input type="text" placeholder="ejemplo@email.com" onChange={(e) => inputEmail(e)} className="input__form"/>
          </label>
          <label>
            Contraseña: <br/>
            <input type="password" placeholder="********" onChange={(e) => inputPassword(e)} className="input__form"/>
          </label>
          <button className="login__btn">Iniciar Sesión</button>
        </form>
        <button className="register__btn--google" onClick={LogInGoogle}>
          Iniciar Sesión con&nbsp;<span style={{ color: "blue" }}>Google</span>
          &nbsp;
          <img src={GoogleLogo} alt="" />
        </button>
        <hr style={{ marginTop: "24px" }} />
        <p className="login__text--bottom">
          ¿No tienes cuenta? Registrate{" "}
          <Link to={"/register"} className="login__link">
            aquí
          </Link>
        </p>
      </div>
    </div>
  );
}

export default LogIn;
