import React from "react";
import "./Video.css"

export default function Video({embedUrl}) {
  return (
    <div className="videoWrapper">
      <iframe
        src={embedUrl}
        allowFullScreen
        className="iframe"
        allow="autoplay"
        title="aboutme__video1"
        frameborder="0"
      ></iframe>
    </div>
  );
}
