import React from "react";
import "./ClassroomClassMaintenance.css";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import MaintenanceBG from "../assets/MaintenanceClassBG.jpg";

function ClassroomClassMaintenance() {
  return (
    <div className="classroom__content">
      <div className="classroom__content--container">
        <div className="classroom__maintenance">
          <figure className="classroom__maintenance--bg--wrapper">
            <img
              src={MaintenanceBG}
              alt=""
              className="classroom__maintenance--bg"
            />
          </figure>
          <div className="classroom__maintenance--container">
            <p style={{ marginBottom: "20px" }}>Página en Mantenimiento</p>
            <p style={{ marginBottom: "20px" }}>
              Por favor prueba con otras clases
            </p>
            <SentimentDissatisfiedIcon
              style={{ fontSize: "60px !important" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassroomClassMaintenance;
