import { collection, getDocs, deleteDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import "./AdminPageUsers.css";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPageUserBackdrop from "./AdminPageUserBackdrop";

function AdminPageUsers() {
  const [usersData, setUsersData] = useState([]);
  const usersRef = collection(db, "users");
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    getUsersData();
  }, []);

  async function getUsersData() {
    setUsersData([]);
    const snapshots = await getDocs(usersRef);
    snapshots.forEach((doc) => {
      setUsersData((usersData) => [...usersData, doc.data()]);
    });
  }

  function closeBackdrop() {
    setIsBackdropOpen(false)
    getUsersData()
  }

  const handleSettingsClick = (user) => {
    setSelectedUser(user);
    setIsBackdropOpen(true);
  };

  return (
    <>
      <h1>Gestionar usuarios</h1>
      <h3 style={{ marginTop: "10px" }}>Lista de Usuarios Registrados</h3>
      <div className="admin__users--list">
        <div className="admin__users--list--top">
          <p className="admin__users--list--top1">Correo Electrónico</p>
          <p className="admin__users--list--top2">Nombre</p>
          <p className="admin__users--list--top3">Estado de Pago</p>
        </div>
        {usersData.length !== 0 &&
          usersData.sort((a, b) => a.email.localeCompare(b.email)).map((user) => {
            return (
                <div className="admin__list--user" key={user.uid}>
                  <p>{user.email}</p>
                  <p>{user.nombre}</p>
                  <div className="admin__list--user--checkboxes">
                    <p onClick={() => handleSettingsClick(user)}>
                      <SettingsIcon className="user__edit--icon" />{" "}
                      &nbsp;Cambiar Acceso:
                    </p>
                    <div>
                      <label>
                        Curso1{" "}
                        <input type="checkbox" className="users__list--checkbox" checked={user.curso1} readOnly/>
                      </label>
                      <label>
                        Curso2 <input type="checkbox" className="users__list--checkbox" checked={user.curso2} readOnly/>
                      </label>
                    </div>
                  </div>
                </div>
            );
          })}
        {isBackdropOpen && <AdminPageUserBackdrop user={selectedUser} onClose={() => closeBackdrop()}/>}
      </div>
    </>
  );
}

export default AdminPageUsers;
