import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics, logEvent, isSupported } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBaYbN45gi_GMiuhbg8BOYW_L8OYyMYTZw",
  authDomain: "geo-uni-web.firebaseapp.com",
  projectId: "geo-uni-web",
  storageBucket: "geo-uni-web.appspot.com",
  messagingSenderId: "670053936439",
  appId: "1:670053936439:web:af9c4135c9003af5397eb2"
};

let analytics = null;

const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth()
const db = getFirestore(app);
const storage = getStorage(app);

isSupported().then((result) => {
  if (result) {
      analytics = getAnalytics(app);
  }
})

export { auth, provider, db, storage, analytics }