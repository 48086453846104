import React from "react";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from "../assets/Logo3.svg";
import { Link } from "react-router-dom";

function Footer() {

  return (
    <section id="footer">
      <div className="row">
        <div className="footer__container">
          <img src={Logo} alt="" className="footer__logo" />
          <div className="footer__description">
            <div className="footer__socials">
              <a href="https://www.facebook.com/geometriadescriptivaydibujoeningenieriauni" target={"_blank"} rel="noopener noreferrer">
                <FacebookIcon className="footer__icon" />
              </a>
              <a href="https://www.youtube.com/@daniellinaresq1872/videos" target={"_blank"} rel="noopener noreferrer">
                <YouTubeIcon className="footer__icon" />
              </a>
              <a href="https://api.whatsapp.com/send?phone=51921417139" target={"_blank"} rel="noopener noreferrer">
                <WhatsAppIcon className="footer__icon" />
              </a>
              <a href="https://www.instagram.com/geodesuni/" target={"_blank"} rel="noopener noreferrer">
                <InstagramIcon className="footer__icon" />
              </a>
            </div>
            <div className="footer__links">
              <Link to={"/"} className="footer__link">
                Inicio
              </Link>
              <Link to={"/courses/descriptive"} className="footer__link">
                Cursos
              </Link>
              <Link to={"/contact"} className="footer__link">
                Contáctenos
              </Link>
              <Link to={"/dashboard"} className="footer__link">
                Aula Virtual
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer__copyright">
        <span className="footer__text">
          GeoDes © 2023. Todos los derechos reservados.{" "}
        </span>
      </div>
    </section>
  );
}

export default Footer;
