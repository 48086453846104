import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebaseConfig";
import "./Classroom.css";
import { Link } from "react-router-dom";
import Logo from "../assets/LogoNav.svg";
import ClassroomContent from "../components/ClassroomContent";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { collection, getDocs, query, where } from "firebase/firestore";

function Classroom({course}) {
  const [user, setUser] = useState("");
  const [userData, setUserData] = useState({})
  const [classesData, setClassesData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getClassesData(course)
  }, [])

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate("/login");
      }
    });
    fetchUserData();
  }, [user]);

  async function fetchUserData() {
    const queryUser = query(
      collection(db, "users"),
      where("uid", "==", `${user.uid}`)
    );

    const snapshots = await getDocs(queryUser);

    const docs = snapshots.docs.map((doc) => doc.data());
    setUserData(docs[0]);
    if (course === "descriptive" && docs[0].curso1 === false) {
      navigate("/dashboard")
    } else if (course === "technicaldrawing" && docs[0].curso2 === false) {
      navigate("/dashboard")
    }
  }

  async function getClassesData(course) {
    setClassesData([]);
    let classesRef
    switch(course) {
      case "descriptive":
        classesRef = collection(db, "course1");
        break
      case "technicaldrawing":
        classesRef = collection(db, "course2");
        break
      default:
        break;
    }

    const snapshots = await getDocs(classesRef);
    snapshots.forEach((doc) => {
      setClassesData((classesData) => [...classesData, doc.data()]);
    });
  }

  const SidebarHTML = () => {
    switch(course) {
      case "descriptive":
        return <>
        {new Array(classesData.length).fill(0).map((_, index) => {
          return <Link to={`/classroom/${course}/class${index + 1}`} className="sidebar__class" key={index}>Clase {index + 1} - Geometría Descriptiva</Link>
        })}
        </>
        break
      case "technicaldrawing":
        return <>
        {new Array(classesData.length).fill(0).map((_, index) => {
          return <Link to={`/classroom/${course}/class${index + 1}`} className="sidebar__class" key={index}>Clase {index + 1} - Dibujo en Ingeniería</Link>
        })}
        </>
        break
      default:
        break;
    }
  }

  return (
    <>
    {Object.keys(userData).length !== 0 && <section id="classroom">
      <div className="classroom__sidebar">
        <Link to={"/"} className="classroom__logo">
          <img src={Logo} alt="Logo"></img>
        </Link>
        <Link to={"/dashboard"} className="sidebar__home--btn">
          <KeyboardDoubleArrowLeftIcon className="sidebar__arrow" />
          Volver al Dashboard
        </Link>
        <Link to={`/classroom/${course}/home`} className="sidebar__class">
          Inicio
        </Link>
        <Link to={`/classroom/${course}/books`} className="sidebar__class">
          Libros del Curso
        </Link>
        {SidebarHTML()}
        {course === 'descriptive' && <Link to={`/classroom/${course}/exam`} className="sidebar__class">
          Examen de Certificación
        </Link>}
      </div>
      <ClassroomContent classesData={classesData} course={course}/>
    </section>}
    </>
  );
}

export default Classroom;
