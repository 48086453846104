import React from 'react'
import './ClassroomEmptyVideo.css'
import WatchLaterIcon from '@mui/icons-material/WatchLater';

function ClassroomEmptyVideo() {
  return (
    <div className='empty-video'>
        <WatchLaterIcon className='empty-video__icon'/>
        <p style={{fontSize:"36px"}}>Por favor vuelva más tarde, el video aún no se encuentra disponible ☺</p>
    </div>
  )
}

export default ClassroomEmptyVideo