import React from 'react'
import HomeBG from "../assets/HomeBG.jpeg";

function ClassroomHome({course}) {
    const renderHomeContent = () => {
        switch(course) {
            case "descriptive":
                return <div className="classroom__content classroom__content--home">
                <figure className="classroom__home--bg-wrapper">
                  <img src={HomeBG} alt="" className="classroom__home--bg" />
                </figure>
                <h2>Bienvenido al curso de GEOMETRÍA DESCRIPTIVA desde CERO</h2>
                <p>
                  El curso consiste en 20 clases grabadas que se van a ir subiendo a
                  la plataforma conforme pasen los días de clases (1-2 días
                  posterior a la clase virtual online), además de material que van a
                  poder encontrar dentro de cada uno de los módulos al ingresar
                  mediante la barra de navegación a la izquierda.
                </p>
                <p>
                  No duden en consultar con el número{" "}
                  <span style={{ fontWeight: "700" }}>+51 921417139</span> o al
                  correo{" "}
                  <span style={{ fontWeight: "700" }}>carlosdlq260@gmail.com</span>{" "}
                  si tienen alguna duda o problema con los cursos o su experiencia
                  de usuario.
                </p>
              </div>
                break
            case "technicaldrawing":
                return <div className="classroom__content classroom__content--home">
                <figure className="classroom__home--bg-wrapper">
                  <img src={HomeBG} alt="" className="classroom__home--bg" />
                </figure>
                <h2>Bienvenido al curso de Dibujo en Ingeniería (Con AutoCAD e Inventor)</h2>
                <p>
                  El curso consiste en 15 clases grabadas que se van a ir subiendo a
                  la plataforma conforme pasen los días de clases (1-2 días
                  posterior a la clase virtual online), además de material que van a
                  poder encontrar dentro de cada uno de los módulos al ingresar
                  mediante la barra de navegación a la izquierda.
                </p>
                <p>
                  No duden en consultar con el número{" "}
                  <span style={{ fontWeight: "700" }}>+51 921417139</span> o al
                  correo{" "}
                  <span style={{ fontWeight: "700" }}>carlosdlq260@gmail.com</span>{" "}
                  si tienen alguna duda o problema con los cursos o su experiencia
                  de usuario.
                </p>
              </div>
            default:
                break
        }
    }
  return (
    <>
    {renderHomeContent()}
    </>
  )
}

export default ClassroomHome