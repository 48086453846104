import { collection, getDocs } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../firebaseConfig";
import "./AdminPageClassesEdit.css";
import BuildIcon from "@mui/icons-material/Build";
import AdminPageClassesEditBackdrop from "./AdminPageClassesEditBackdrop";

function AdminPageClassesEdit() {
  const [classesData, setClassesData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [courseActive, setCourseActive] = useState("")
  const [backdropAction, setBackdropAction] = useState("")

  async function getClassesData(course) {
    setCourseActive(course)
    const classesRef = collection(db, course);
    setClassesData([]);
    const snapshots = await getDocs(classesRef);
    snapshots.forEach((doc) => {
      setClassesData((classesData) => [...classesData, doc.data()]);
    });
  }

  function handleEditClick(doc) {
    setBackdropAction ("edit")
    setSelectedClass(doc)
    setIsBackdropOpen(true)
  }

  function handleAddClick() {
    setBackdropAction("add")
    setIsBackdropOpen(true)
  }

  function closeBackdrop(courseActive) {
    setIsBackdropOpen(false)
    getClassesData(courseActive)
  }

  return (
    <>
      <h1>Modificar estado de clases</h1>
      <p style={{ marginTop: "12px", fontSize: "24px" }}>
        Elige el curso a editar
      </p>
      <select
        name=""
        id=""
        defaultValue={""}
        style={{ padding: "4px 8px", marginTop: "12px", width: "300px" }}
        onChange={(e) => getClassesData(e.target.value)}
      >
        <option value="" disabled>
          Selecciona curso...
        </option>
        <option value="course1">Geometría Descriptiva desde Cero</option>
        <option value="course2">Dibujo en Ingeniería</option>
      </select>
      {courseActive && <button className="admin__classes--add-btn" onClick={handleAddClick}>Agregar Clase +</button>}
      <div className="admin__classes--list">
        <div className="admin__classes--list-top">
          <p className="admin__classes--list-title admin__classes--list-1">
            Clase
          </p>
          <p className="admin__classes--list-title admin__classes--list-2">
            Título
          </p>
          <p className="admin__classes--list-title admin__classes--list-3">
            Estado de clase
          </p>
        </div>
        <div className="admin__classes--list-content">
          {classesData.length !== 0 &&
            classesData.sort((a,b) => a.classNum - b.classNum).map((classDoc) => {
              return (
                <div className="admin__list--class" key={classDoc.classNum}>
                  <p className="admin__list--class--paragraph1">Clase {classDoc.classNum}</p>
                  <p className="admin__list--class--paragraph2">
                    {classDoc.title}
                  </p>
                  <div className="admin__list--class--checkboxes">
                    En Funcionamiento &nbsp;
                    <input type="checkbox" checked={classDoc.active} readOnly/> &nbsp;| En Mantenimiento &nbsp;
                    <input type="checkbox" checked={!classDoc.active} readOnly/>
                    <span onClick={() => handleEditClick(classDoc)}>
                      <BuildIcon className="admin__list--wrench-icon" /> Editar
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {isBackdropOpen && (
        <AdminPageClassesEditBackdrop action={backdropAction} doc={selectedClass} course={courseActive}
          onClose={() => closeBackdrop(courseActive)}
        />
      )}
    </>
  );
}

export default AdminPageClassesEdit;
