import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import "./AdminPageUserBackdrop.css";
import CloseIcon from "@mui/icons-material/Close";
import { deleteDoc } from "firebase/firestore";

function AdminPageUserBackdrop({ user, onClose }) {
  const [newCourse1Value, setNewCourse1Value] = useState(null);
  const [newCourse2Value, setNewCourse2Value] = useState(null);
  const [checkDeleteUser, setCheckDeleteUser] = useState(false);

  useEffect(() => {
    setNewCourse1Value(user.curso1);
    setNewCourse2Value(user.curso2);
  }, []);

  function clickCheckbox(value) {
    let messageEl = document.querySelector(".user__edit--backdrop--message");
    messageEl.innerHTML = "";
    setNewCourse1Value(value);
  }

  function clickCheckbox2(value) {
    let messageEl = document.querySelector(".user__edit--backdrop--message");
    messageEl.innerHTML = "";
    setNewCourse2Value(value);
  }

  async function handleUpdate() {
    const userRef = query(
      collection(db, "users"),
      where("uid", "==", `${user.uid}`)
    );

    const userSnapshot = await getDocs(userRef);
    const userDoc = userSnapshot.docs[0].ref;

    updateDoc(userDoc, {
      curso1: newCourse1Value,
      curso2: newCourse2Value,
    })
      .then(() => {
        let messageEl = document.querySelector(
          ".user__edit--backdrop--message"
        );
        messageEl.innerHTML = "Valor cambiado exitosamente";
      })
      .catch((error) => {
        console.log("Error al editar: ", error);
      });
  }

  async function deleteUser() {
    let messageEl = document.querySelector(".user__edit--backdrop--message");
    const userRef = query(
      collection(db, "users"),
      where("email", "==", `${user.email}`)
    );

    const userSnapshot = await getDocs(userRef);
    const userDoc = userSnapshot.docs[0].ref;

    deleteDoc(userDoc)
      .then(() => {
        messageEl.innerHTML = "Usuario eliminado correctamente";
      })
      .catch(() => {
        messageEl.innerHTML = "Error al eliminar usuario. Intente Nuevamente";
      });
  }

  function handleCheckDeteleUSer(value) {
    setCheckDeleteUser(value)
  }

  return (
    <>
      <div className="user__edit--backdrop">
        <div className="user__edit--container">
          <span className="admin__user--edit" onClick={() => onClose()}>
            Cancelar <CloseIcon className="user__edit--container--icon" />
          </span>
          <h3 style={{ textAlign: "center" }}>
            Dar/Quitar Acceso al Aula Virtual
          </h3>
          <p
            style={{
              marginTop: "12px",
              marginBottom: "12px",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Usuario: {user.email}
          </p>
          <label style={{ textAlign: "center", fontSize: "20px" }}>
            Curso1{" "}
            <input
              type="checkbox"
              style={{ width: "18px", height: "18px" }}
              defaultChecked={user.curso1}
              onChange={(e) => clickCheckbox(e.target.checked)}
            />
          </label>
          <label style={{ textAlign: "center", fontSize: "20px" }}>
            Curso2{" "}
            <input
              type="checkbox"
              style={{ width: "18px", height: "18px" }}
              defaultChecked={user.curso2}
              onChange={(e) => clickCheckbox2(e.target.checked)}
            />
          </label>
          <p
            className="user__edit--backdrop--message"
            style={{ color: "#192b80", textAlign: "center" }}
          ></p>
          <label style={{ textAlign: "center", fontSize: "20px" }}>
          Quieres eliminar el usuario?{" "}
            <input
              type="checkbox"
              style={{ width: "18px", height: "18px",marginTop:"8px" }}
              onChange={(e) => handleCheckDeteleUSer(e.target.checked)}
            />
          </label>
          <div className="user__edit--btns">
            <button
              style={{
                margin: "12px auto 0 auto",
                fontSize: "22px",
                padding: "2px 16px",
              }}
              onClick={handleUpdate}
            >
              Enviar
            </button>
            {checkDeleteUser && (
              <button
                style={{
                  margin: "12px auto 0 auto",
                  fontSize: "18px",
                  padding: "1px 12px",
                  backgroundColor: "red",
                }}
                onClick={deleteUser}
              >
                Eliminar Usuario
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminPageUserBackdrop;
