import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebaseConfig";
import "./ClassroomContent.css";
import ClassroomClassMaintenance from "./ClassroomClassMaintenance";
import ClassroomBooks from "./ClassroomBooks";
import ClassDownloads from "./ClassDownloads";
import ClassroomHome from "./ClassroomHome";
import ClassroomClassBg from "../assets/ClassroomClassBG.webp"
import ClassroomBooksBG from "../assets/BooksBG.jpg"
import ClassroomExam from "./ClassroomExam";

function ClassroomContent({classesData, course}) {
  let { classNum } = useParams();
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    getPageContent();
  }, [classNum]);

  async function getPageContent() {
    let queryClass
    switch(course) {
      case "descriptive":
        queryClass = query(
          collection(db, "course1"),
          where("clase", "==", classNum)
        );
        break
      case "technicaldrawing":
        queryClass = query(
          collection(db, "course2"),
          where("clase", "==", classNum)
        );
        break
      default:
        break;
    }
    const snapshots = await getDocs(queryClass);

    const docs = snapshots.docs.map((doc) => doc.data());

    setPageData(docs[0]);
  }

  const renderContent = () => {
    let classesStatus = {};

    classesData.forEach((classDoc) => {
      classesStatus = {...classesStatus,
        [classDoc.clase]: classDoc.active,
      }
    })

    switch (classNum) {
      case "home":
        return <ClassroomHome course={course} />;
      case "books":
        return (
          <div className="classroom__content">
            <img src={ClassroomBooksBG} alt="" className="classroom__content--bg"/>
            <div className="classroom__content--container">
              <h2 style={{color:'black'}}>
                Estos son los libros que van a necesitar durante el curso.
              </h2>
              <ClassroomBooks course={course}/>
            </div>
          </div>
        );
      case "exam":
        return <ClassroomExam course={course}/>;
      default:
        if (!classesStatus[classNum]) return <ClassroomClassMaintenance />;
        return (
          <div className="classroom__content">
            <img src={ClassroomClassBg} alt="" className="classroom__content--bg"/>
            <div className="classroom__content--container">
              <h2>{pageData?.title}</h2>
              <ClassDownloads course={course}/>
            </div>
          </div>
        );
    }
  };

  return <>{classesData.length !== 0 && renderContent()}</>;
}

export default ClassroomContent;
