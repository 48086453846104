import React, { useEffect, useState } from "react";
import "./AdminPageClassesEditBackdrop.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

function AdminPageClassesEditBackdrop({ action, course, doc, onClose }) {
  const [newClassActiveValue, setNewClassActiveValue] = useState(null);
  const [newClassData, setNewClassData] = useState({});

  useEffect(() => {
    setNewClassActiveValue(doc.active);
    setNewClassData({ ...newClassData, active: false });
  }, []);

  function onClickCheckbox(status) {
    let messageEl = document.querySelector(
      ".classes__edit--backdrop--message"
    );
    messageEl.innerHTML = "";

    const checkboxesList = document.querySelectorAll(
      ".classes__backdrop--checkbox"
    );

    for (let i = 0; i < 2; i++) {
      checkboxesList[i].checked = false;
    }

    if (status) {
      checkboxesList[0].checked = true;
      setNewClassActiveValue(true);
    } else {
      checkboxesList[1].checked = true;
      setNewClassActiveValue(false);
    }
  }

  async function createClass() {
    if (!newClassData.title || !newClassData.classNum) {
      let messageEl = document.querySelector(
        ".classes__edit--backdrop--message"
      );
      messageEl.innerHTML = "Error. Espacios vacíos.";
      return;
    }

    const classExists = await checkIfClassExistsInFirestore(
      newClassData.classNum
    );

    if (!classExists) {
      let messageEl = document.querySelector(
        ".classes__edit--backdrop--message"
      );
      messageEl.innerHTML = `Ya existe la clase ${newClassData.classNum}`;
      return;
    }

    addDoc(collection(db, course), {
      active: newClassData.active,
      title: newClassData.title,
      classNum: parseInt(newClassData.classNum),
      clase: `class${newClassData.classNum}`,
    })
      .then(() => {
        let messageEl = document.querySelector(
          ".classes__edit--backdrop--message"
        );
        messageEl.innerHTML = "Clase creada exitosamente";
      })
      .catch((e) => {
        let messageEl = document.querySelector(
          ".classes__edit--backdrop--message"
        );
        messageEl.innerHTML = "Ocurrió un error al crear la clase";
      });
  }

  async function checkIfClassExistsInFirestore(classNum) {
    const queryClass = query(
      collection(db, course),
      where("classNum", "==", parseInt(classNum))
    );

    const snapshots = await getDocs(queryClass);

    return snapshots.empty;
  }

  async function updateClassValue() {
    if (newClassActiveValue === doc.active) {
      return;
    } else {
      const classRef = query(
        collection(db, course),
        where("clase", "==", `${doc.clase}`)
      );

      const classSnapshot = await getDocs(classRef);
      const classDoc = classSnapshot.docs[0].ref;

      updateDoc(classDoc, {
        active: newClassActiveValue,
      })
        .then(() => {
          let messageEl = document.querySelector(
            ".classes__edit--backdrop--message"
          );
          messageEl.innerHTML = "Valor cambiado exitosamente";
        })
        .catch((error) => {
          let messageEl = document.querySelector(
            ".classes__edit--backdrop--message"
          );
          messageEl.innerHTML = "Error al editar, inténtalo nuevamente";
        });
    }
  }

  async function deleteClass() {
    let messageEl = document.querySelector(
      ".classes__edit--backdrop--message"
    );
    const classRef = query(
      collection(db, course),
      where("clase", "==", `${doc.clase}`)
    );

    const classSnapshot = await getDocs(classRef);
    const classDoc = classSnapshot.docs[0].ref;

    deleteDoc(classDoc).then(() => {
      messageEl.innerHTML = "Clase borrada exitosamente";
    }).catch((e) => {
      messageEl.innerHTML = "Ocurrió un error al borrar la clase. Vuelve a intentarlo";
    })
  }

  function backdropActionElements() {
    switch (action) {
      case "edit":
        return (
          <>
            <p>
              Modificando actualmente:
              <span
                style={{
                  color: "#192b80",
                  fontWeight: "700",
                  display: "inline",
                  marginLeft: "6px",
                }}
              >
                Clase {doc.classNum}
              </span>
            </p>
            <label>
              En Funcionamiento{" "}
              <input
                type="checkbox"
                defaultChecked={doc.active}
                className="classes__backdrop--checkbox"
                onClick={() => onClickCheckbox(true)}
              />
            </label>
            <label>
              En Mantenimiento{" "}
              <input
                type="checkbox"
                defaultChecked={!doc.active}
                className="classes__backdrop--checkbox"
                onClick={() => onClickCheckbox(false)}
              />
            </label>
            <p
              className="classes__edit--backdrop--message"
              style={{
                textAlign: "center",
                color: "#192b80",
                marginBottom: "-8px",
                marginTop: "8px",
              }}
            ></p>
            <div className="classes__edit--backdrop--btns" style={{display:"flex", justifyContent:"space-around"}}>
              <button
                className="classes__edit--backdrop--btn"
                onClick={() => updateClassValue()}
              >
                EDITAR
              </button>
              <button className="classes__edit--backdrop--btn" style={{width:"180px", color:"red"}} onClick={deleteClass}>ELIMINAR CLASE</button>
            </div>
          </>
        );
      case "add":
        return (
          <>
            <p
              style={{
                marginBottom: "12px",
                textAlign: "center",
                color: "#192b80",
                fontWeight: "700",
                fontSize: "24px",
              }}
            >
              Crear Nueva Clase
            </p>
            <form
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label style={{ marginBottom: "12px" }}>
                {" "}
                Clase Número:{" "}
                <input
                  style={{ marginLeft: "16px" }}
                  type="number"
                  min="1"
                  max="20"
                  onChange={(e) =>
                    setNewClassData({
                      ...newClassData,
                      classNum: e.target.value,
                    })
                  }
                  required
                />
              </label>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px auto 12px auto",
                }}
              >
                {" "}
                Título:{" "}
                <textarea
                  style={{
                    marginLeft: "16px",
                    width: "300px",
                    height: "50px",
                    resize: "none",
                  }}
                  onChange={(e) =>
                    setNewClassData({ ...newClassData, title: e.target.value })
                  }
                  required
                />
              </label>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 auto",
                }}
              >
                {" "}
                En Funcionamiento?:{" "}
                <input
                  style={{ marginLeft: "16px" }}
                  type="checkbox"
                  onChange={(e) =>
                    setNewClassData({
                      ...newClassData,
                      active: e.target.checked,
                    })
                  }
                  className="classes__edit--add--checkbox"
                />
              </label>
            </form>
            <p
              className="classes__edit--backdrop--message"
              style={{
                textAlign: "center",
                color: "#192b80",
                marginBottom: "-8px",
                marginTop: "8px",
              }}
            ></p>
            <button
              className="classes__edit--backdrop--btn"
              onClick={createClass}
            >
              CREAR
            </button>
          </>
        );
      default:
        break;
    }
  }

  return (
    <>
      <div className="classes__edit--backdrop">
        <div className="classes__edit--backdrop--container">
          <span onClick={() => onClose()}>
            <CloseIcon className="classes__edit--backdrop--close-icon" /> Cerrar
          </span>
          {backdropActionElements()}
        </div>
      </div>
    </>
  );
}

export default AdminPageClassesEditBackdrop;
