import React, { useState } from "react";
import "./Register.css";
import Background from "../assets/RegisterBG.jpg";
import { Link, useNavigate } from "react-router-dom";
import GoogleLogo from "../assets/GoogleLogo.svg";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { auth, db, provider } from "../firebaseConfig.js";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { collection, addDoc, serverTimestamp, query, where, getDocs } from "firebase/firestore";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  function inputEmail(e) {
    setEmail(e.target.value);
  }

  function inputPassword(e) {
    setPassword(e.target.value);
  }

  function registerUser(e) {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        addUserFirestore(user);
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function addUserFirestore(user) {
    addDoc(collection(db, "users"), {
      email: user.email,
      uid: user.uid,
      timestamp: serverTimestamp(),
      curso1: false,
      nombre: user.displayName || `Invitado@${Math.floor(Math.random()*10000)}`,
      photoURL: user.photoURL || null,
    });
    setEmail("");
    setPassword("");
  }

  function signInGoogle() {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;

        return user
      }).then(async function (user) {
        const checkExists = await checkIfUserExistsInFirestore(user.email)

        if (!checkExists) {
          addUserFirestore(user);
         }

        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error)
      });
  }

  async function checkIfUserExistsInFirestore(email) {
    const queryUser = query(
      collection(db, "users"),
      where("email", "==", email)
    );

    const snapshots = await getDocs(queryUser);

    return !snapshots.empty
  }

  return (
    <div id="register">
      <figure className="register__img--wrapper">
        <img src={Background} alt="" className="register__img" />
      </figure>
      <div className="register__container">
        <div className="login__return">
          <Link to={"/"} className="login__return--anchor">
            <KeyboardDoubleArrowLeftIcon className="login__return-icon" />{" "}
            <span className="login__return--text">Inicio</span>
          </Link>
        </div>
        <hr />
        <h2 className="register__text">¡Regístrate Gratis!</h2>
        <form
          onSubmit={(e) => registerUser(e)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label style={{ marginBottom: "12px" }}>
            Correo Electrónico:
            <input
              className="input__form"
              type="text"
              placeholder="ejemplo@email.com"
              onChange={(e) => inputEmail(e)}
            />
          </label>
          <label>
            Contraseña:
            <input
              className="input__form"
              type="text"
              placeholder="********"
              onChange={(e) => inputPassword(e)}
            />
          </label>
          <button className="register__btn">Enviar</button>
        </form>
        <hr style={{ marginTop: "24px" }} />
        <p className="register__text--top">
          También puedes registrarte con tu cuenta Google:
        </p>
        <button className="register__btn--google" onClick={signInGoogle}>
          Registrarse con&nbsp;<span style={{ color: "blue" }}>Google</span>
          &nbsp;
          <img src={GoogleLogo} alt="" />
        </button>

        <p className="register__text--bottom">
          ¿Ya tienes cuenta?{" "}
          <Link to={"/login"} className="register__link">
            Iniciar Sesión
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Register;
